import Image from 'next/image'
import { Typography } from '@mui/material'
import { motion, useAnimation } from 'framer-motion'

import { AwardCardContainer, AwardCardContent, AwardCardImageContainer } from './styled'
import { useEffect } from 'react'

export default function AwardCard({ description, image, altText, placement, year }: IAwardResult) {
  const controls = useAnimation()

  useEffect(() => {
    ;(async () => {
      controls.set({ opacity: 0 })
      await controls.start({ opacity: 1 })
    })()
  }, [controls, description])

  return (
    <motion.div initial={{ opacity: 1 }} animate={controls} transition={{ duration: 2 }}>
      <AwardCardContainer>
        <AwardCardContent>
          <Typography gutterBottom variant="h6" component="p" color="white">
            {placement}
          </Typography>
          <Typography gutterBottom variant="body2" color="white">
            {description}
          </Typography>
          <Typography gutterBottom variant="body2" color="white">
            {year}
          </Typography>
        </AwardCardContent>
        <AwardCardImageContainer>
          {image?.retinaSize && (
            <Image src={image.retinaSize} alt={altText} layout="fill" objectFit="contain" />
          )}
        </AwardCardImageContainer>
      </AwardCardContainer>
    </motion.div>
  )
}
