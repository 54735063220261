import { styled, Box, Container } from '@mui/material'
import { preventForwardProp } from 'utils/styledUtils'
import { IAwardsContainer, IAwardsWrapper } from './types'

export const AwardsContainer = styled(
  Container,
  preventForwardProp('hideDescription'),
)<IAwardsContainer>(({ theme, hideDescription }) => ({
  padding: theme.spacing(0, 3),
  maxWidth: '1440px',
  display: 'grid',
  gap: theme.spacing(6),
  marginBottom: theme.spacing(5),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 8),
  },
  [theme.breakpoints.up('lg')]: {
    ...(!hideDescription && {
      gridTemplateColumns: '40% 60%',
    }),

    padding: theme.spacing(0, 10),
  },
  [theme.breakpoints.up('xl')]: {
    padding: 0,
  },
}))

export const AwardsWrapper = styled(
  Box,
  preventForwardProp('hideDescription'),
)<IAwardsWrapper>(({ theme, hideDescription }) => ({
  backgroundColor: '#000',
  display: 'grid',
  gridTemplateColumns: '1fr',
  justifyContent: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  ...(!hideDescription && {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '624px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '720px',
    },
  }),
}))

export const CarouselItemsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
}))
