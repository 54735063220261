import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from '@baseapp-frontend/core'
import { useMediaQuery, Theme, Box } from '@mui/material'

import AwardsDescription from './AwardDescription'
import AwardCard from './AwardCard'
import BadgesList from './BadgesList'
import { AwardsContainer, AwardsWrapper } from './styled'

import AwardsApi from 'api/AwardsApi'

const awardsPerSlideMap = {
  xs: 2,
  sm: 4,
  md: 6,
  lg: 6,
  xl: 6,
}

const randomizeIndex = (length: number, previousNumber = 0): number => {
  if (length <= 1) return 0
  let number = Math.floor(Math.random() * length)
  while (number === previousNumber) {
    number = Math.floor(Math.random() * length)
  }
  return number
}

export default function AwardsSection({ hideDescription = false }: { hideDescription?: boolean }) {
  const { data, isFetching } = useQuery('awards', () => AwardsApi.getAwards())

  const [awardItems, setAwardItems] = useState<IAwardResult[]>([])
  const previousIndexRef = useRef(0)

  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const upMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const upLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const upXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'))

  const awardsPerSlide = useMemo(
    () => awardsPerSlideMap[upXl ? 'xl' : upLg ? 'lg' : upMd ? 'md' : upSm ? 'sm' : 'xs'],
    [upXl, upLg, upMd, upSm],
  )

  useEffect(() => {
    const awards = data?.results || []
    setAwardItems(awards)
  }, [data?.results])

  useEffect(() => {
    if (awardItems.length === 0) {
      return
    }

    const timer = setInterval(() => {
      const currentIndex = randomizeIndex(awardsPerSlide, previousIndexRef.current)
      previousIndexRef.current = currentIndex

      setAwardItems((prev) => {
        const updatedAwards = prev.reduce((acc, curr, index, arr) => {
          if (index === currentIndex) {
            return [...acc, arr[awardsPerSlide]]
          }

          if (index === awardsPerSlide) {
            return [...acc]
          }

          if (index === arr.length - 1) {
            return [...acc, curr, arr[currentIndex]]
          }

          return [...acc, curr]
        }, [] as IAwardResult[])

        return updatedAwards
      })
    }, 5000)

    return () => clearInterval(timer)
  }, [data?.results, awardsPerSlide, isFetching, awardItems.length])

  return (
    <AwardsContainer hideDescription={hideDescription}>
      <Box component="div" sx={{ display: hideDescription ? 'none' : 'block' }}>
        <AwardsDescription />
        <BadgesList />
      </Box>
      <AwardsWrapper hideDescription={hideDescription}>
        {awardItems.slice(0, awardsPerSlide).map((award, index) => (
          <AwardCard key={index} {...award} />
        ))}
      </AwardsWrapper>
    </AwardsContainer>
  )
}
